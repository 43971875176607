<template>
  <div class="agency-info">
    <h5>Assistenza e info</h5>
    <p>
      Telefono: 02.407.413.58
      <br />
      Email: assistenza@wichee.com - info@wichee.com
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.agency-info {
  margin-top: 20%;
}
</style>
