<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <section class="cont">
        <img
          :src="require('@/assets/lisaweb.png')"
          alt="Lisaweb 3"
          class="left-logo"
        />
        <div class="content">
          <div class="login" v-if="!isLoading">
            <h3>Richiedi una nuova password</h3>
            <validation-observer
              ref="observer"
              v-slot="{ invalid, handleSubmit }"
            >
              <b-form @submit.prevent="handleSubmit(onSubmit)">
                <div class="mt-5">
                  <!-- <validation-provider :rules="getRules('email', 'forgot_password')"> -->
                  <validation-provider :rules="{ required: true }">
                    <b-input-group prepend="E-Mail">
                      <b-form-input
                        name="email"
                        vid="email"
                        v-model="form[rep].email"
                        label="E-Mail"
                        type="text"
                        autocomplete="off"
                      ></b-form-input>
                    </b-input-group>
                  </validation-provider>
                  <!-- <base-input
                    name="email"
                    vid="email"
                    v-model="form.email"
                    label="E-Mail"
                    type="text"
                    autocomplete="off"
                    :rules="{ required: true }"
                  /> -->
                  <!-- :rules="getRules('email', 'forgot_password')" -->
                  <div style="font-size: 0.75rem" class="mt-3">
                    <b-link href="login" @click.prevent="login()">
                      <span>Torna alla login?</span>
                    </b-link>
                  </div>
                  <div style="display: flex; justify-content: flex-end">
                    <b-button
                      type="submit"
                      variant="lisaweb"
                      size="sm"
                      :disabled="invalid"
                      >Richiedi</b-button
                    >
                  </div>
                </div>
              </b-form>
            </validation-observer>
            <Info></Info>
          </div>
        </div>
      </section>
      <Footer :version="version"></Footer>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">{{ msg || "Operazione in corso..." }}</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import BaseInput from "@/components/form/BaseInput";
import BaseIcon from "@/components/BaseIcon";
import Info from "@/components/Info";
import Footer from "@/components/Footer";
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  data() {
    return {
      isLoading: false,
      repository: "forgot_password",
      bgImage: { "background-image": 'url("../assets/lisaweb.png")' },
      form: {
        forgot_password: {
          email: "",
        },
      },
      // show: true,
      // messages: [
      //   "Caricamento dati in corso...",
      //   "Questa operazione potrebbe impiegare alcuni secondi...",
      //   "Attendere prego...",
      // ],
      delay: 6000,
    };
  },
  methods: {
    onSubmit() {
      const Repo = RepositoryFactory.get(this.rep);
      Repo.store(this.form[this.rep])
        .then(() => {
          this.$showSnackbar({
            preset: "info",
            text: `Richiesta inoltrata correttamente: a breve riceverai una e-mail alla casella "${
              this.form[this.rep].email
            }" contenente le istruzioni per effettuare il cambio.`,
            duration: 10000,
          });
          this.login();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
    },
    login() {
      this.$router.replace({ name: "Login" });
    },
  },
  components: {
    // BaseInput,
    BaseIcon,
    Info,
    Footer,
  },
  mounted() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    formFilled() {
      return !(this.form.username || this.form.password);
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.form-control,
.btn {
  height: unset;
}
.login {
  width: 100%;
  height: 100%;
  max-width: 450px;
  padding: 100px 20px;
  margin: auto;
  font-size: 16px;
  line-height: 25px;
}

h3 {
  font-weight: bold;
}

h5 {
  font-weight: bold;
}

.footer {
  margin-top: 20%;
}
.cont {
  display: flex;
  height: 100vh;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.bg-image {
  /* The image used */
  background-image: url("../assets/lisaweb.png");

  /* Center and scale the image nicely */
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% 100%;
  flex: 1;
  height: 100%;
}

.left-logo {
  width: 500px;
  max-width: 50%;
  min-width: 25%;
}

.content {
  flex: 2;
  height: 100%;
  overflow: auto;
}

/* Fluid container API */
p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.fluid-container.api {
  background: #6f6259;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.fluid-container.api > *:last-child {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1rem;
}
</style>
