<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <section class="cont">
        <img
          :src="require('@/assets/lisaweb.png')"
          alt="Lisaweb 3"
          class="left-logo"
        />
        <div class="content">
          <div class="login" v-if="!isLoading">
            <h3>Imposta la nuova password</h3>
            <validation-observer
              ref="observer"
              v-slot="{ invalid, handleSubmit }"
            >
              <b-form @submit.prevent="handleSubmit(onSubmit)">
                <div class="mt-5">
                  <!-- <validation-provider :rules="getRules('password', 'reset_password')"> -->
                  <validation-provider :rules="{ required: true }">
                    <!-- email: {{ form[rep].email }} <br />
                    token: {{ form[rep].token }} -->
                    <b-input-group prepend="Password">
                      <b-form-input
                        name="password"
                        vid="password"
                        v-model="form[rep].password"
                        label="Password"
                        :type="showPassword ? 'text' : 'password'"
                        autocomplete="off"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :pressed.sync="showPassword"
                          class=""
                          size="sm"
                          text="Button"
                          variant="lisaweb"
                          :title="showPassword ? 'Nascondi' : 'Mostra'"
                          ><b-icon icon="eye" v-if="!showPassword"></b-icon>
                          <b-icon v-else icon="eye-slash-fill"></b-icon
                        ></b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </validation-provider>
                  <!-- <b-button-group>
                    <base-input
                      name="password"
                      vid="password"
                      v-model="form.password"
                      label="Password"
                      :type="showPassword ? 'text' : 'password'"
                      autocomplete="off"
                      :rules="{ required: true }"
                    />
                    <b-button
                      class=""
                      size="sm"
                      text="Button"
                      variant=""
                      :title="showPassword ? 'Nascondi' : 'Mostra'"
                      :pressed.sync="showPassword"
                      ><b-icon icon="eye" v-if="!showPassword"></b-icon>
                      <b-icon v-else icon="eye-slash-fill"></b-icon
                    ></b-button>
                  </b-button-group> -->
                  <div style="display: flex; justify-content: flex-end">
                    <b-button
                      type="submit"
                      variant="lisaweb"
                      size="sm"
                      class="mt-3"
                      :disabled="invalid"
                      >Reimposta</b-button
                    >
                  </div>
                </div>
              </b-form>
            </validation-observer>
            <Info></Info>
          </div>
        </div>
      </section>
      <!-- <Footer></Footer> -->
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">{{ msg || "Operazione in corso..." }}</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import BaseInput from "@/components/form/BaseInput";
import BaseIcon from "@/components/BaseIcon";
import FormMixin from "@/mixins/FormMixin";
import Info from "@/components/Info";
// import Footer from "@/components/Footer";

export default {
  mixins: [FormMixin],
  data() {
    return {
      isLoading: false,
      repository: "reset_password",
      showPassword: false,
      bgImage: { "background-image": 'url("../assets/lisaweb.png")' },
      form: {
        reset_password: {
          email: this.$route.query.email,
          password: "",
          token: this.$route.params.token,
        },
      },
      // show: true,
      // messages: [
      //   "Caricamento dati in corso...",
      //   "Questa operazione potrebbe impiegare alcuni secondi...",
      //   "Attendere prego...",
      // ],
      delay: 6000,
    };
  },
  components: {
    BaseIcon,
    // BaseInput,
    Info,
    // Footer,
  },
  mounted() {
    // error message: "The GET method is not supported for this route. Supported methods: POST
    // this.isLoading = true;
    // this.fetchCreateForm(this.repository).then(() => {
    //   this.isLoading = false;
    // });
  },

  methods: {
    onSubmit() {
      const Repo = RepositoryFactory.get(this.rep);
      Repo.store(this.form[this.rep])
        .then(() => {
          this.$showSnackbar({
            preset: "info",
            text: `Password Modificata con Successo`,
          });
          this.login();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
    },
    login() {
      this.$router.replace({ name: "Login" });
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.form-control,
.btn {
  height: unset;
}

.login {
  width: 100%;
  height: 100%;
  max-width: 450px;
  padding: 100px 20px;
  margin: auto;
  font-size: 16px;
  line-height: 25px;
}

h3 {
  font-weight: bold;
}

h5 {
  font-weight: bold;
}

.footer {
  margin-top: 20%;
}
.cont {
  display: flex;
  height: 100vh;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.bg-image {
  /* The image used */
  background-image: url("../assets/lisaweb.png");

  /* Center and scale the image nicely */
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% 100%;
  flex: 1;
  height: 100%;
}

.left-logo {
  width: 500px;
  max-width: 50%;
  min-width: 25%;
}

.content {
  flex: 2;
  height: 100%;
  overflow: auto;
}

/* Fluid container API */
p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.fluid-container.api {
  background: #6f6259;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.fluid-container.api > *:last-child {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1rem;
}
</style>
